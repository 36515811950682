import { Action } from '../actions/actions'

function tradingHistoryReducer(
  state = {
    deals: [],
    totalCount: 0,
  },
  action: any,
) {
  switch (action.type) {
    case Action.FlushDeals:
      return {
        deals: [],
        totalCount: 0,
      }
    case Action.GotDeals:
      return {
        ...state,
        deals: action.data.Data.map((item: any) => {
          return {
            ...item,
            id: Math.random() * (10000 - 1 + 1) + 1,
          }
        }),
        totalCount: action.data.TotalCount,
      }
    default:
      return state
  }
}

export default tradingHistoryReducer
