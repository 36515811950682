import React from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { ConnectionString } from '../../entity/configuration'
import { ExtApiPlatformEntity, FixApiOZPlatformEntity, FixApiPlatformEntity, FixApiPXMPlatformEntity, Mt4PlatformEntity, Mt5PlatformEntity, MtExecPlatformEntity, PlatformType } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import usePostfix from '../../hooks/usePostfix'
import useVersion from '../../hooks/useVersion'
import { IVersion } from '../../types/version'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { folderNameSchema } from '../../utils/schema-utils'
import ExtApiPlatformEdit from './ExtApiPlatformEdit'
import FixApiPlatformEdit from './FixApiPlatformEdit'
import MT4PlatformEdit from './MT4PlatformEdit'
import MT5PlatformEdit from './MT5PlatformEdit'
import MtExecPlatformEdit from './MtExecPlatformEdit'

interface IState {
  Type: any
  Name: string
}

const INITIAL_STATE: IState = {
  Type: { value: PlatformType.mt5, label: 'MT5' },
  Name: '',
}

const convert = (option: string) => {
  switch (option) {
    case 'mt4':
      return 'MT4'
    case 'mt5':
      return 'MT5'
    case 'fixapi':
      return 'FIX-API'
    case 'fixapioz':
      return 'FIX-TypeO'
    case 'fixapipxm':
      return 'FIX-TypeX (beta)'
    case 'extapi':
      return 'EXT-Platform'
    case 'ctrader':
      return 'cTrader'
    case 'matchtrade':
      return 'MatchTrade'
    case 'mtexec':
      return 'CoverageMT5'
    default:
      return 'none'
  }
}

function makeSchemaName(platform: string, version: IVersion, coverage: boolean = false): string {
  const gatewayVersion = version.GatewayVersion
  if (typeof gatewayVersion === 'string' && platform.length) {
    if (coverage) {
      return platform + '_coverage_' + gatewayVersion.split('.', 3).join('.')
    }
    return platform + '_' + gatewayVersion.split('.', 3).join('.')
  }
  return ''
}

const schema = {
  Name: folderNameSchema(),
  Type: yup.string().required(),
}

const AddPlatform: React.FC<IGatewayProp> = ({ gatewayName }) => {
  const [common, setCommon, touched, setTouched, errors, isValid] = useFormValidation(INITIAL_STATE, schema)
  const version = useVersion(gatewayName)
  const platforms = useSelector((state: any) => state.platforms)
  const logPath = usePostfix('_log')
  const storePath = usePostfix('_store')

  const alreadyExist = () => {
    if (platforms.find((platform: any) => platform.Name.toLowerCase() === common.Name.toLowerCase())) {
      errors.Name = true
      return 'platform.exists'
    }
    return ''
  }

  if (version === null) {
    return null
  }

  const port = platforms.map((item: any) => {
    if (item.Type === 'mt5') {
      const [, port] = item.GatewayAddress.split(':')
      return Number(port)
    } else {
      return item.SocketAcceptPort
    }
  })

  const changedObjectPart = {
    Name: common.Name.trim(),
    ConnectionString: { ...new ConnectionString({}), Database: makeSchemaName(common.Name, version) },
    FileStorePath: storePath(common.Name),
    FileLogPath: logPath(common.Name),
    Type: common.Type.value,
  }

  return (
    <>
      <Card>
        <Card.Header className="color-dark font-500">
          <FormattedMessage id="platform.add-platform" />
        </Card.Header>
        <Card.Body>
          {buildControlsExtTwoPerLine(
            [
              sselectInput('Type').optionItems(
                Object.values(PlatformType).map((item: any) => {
                  return {
                    value: item,
                    label: convert(item),
                  }
                }),
              ),
              textInput('Name').errorMessage(alreadyExist()),
            ],
            common,
            setCommon,
            'platform',
            touched,
            setTouched,
            errors,
          )}
        </Card.Body>
      </Card>
      {common.Type.value === PlatformType.mt5 && (
        <MT5PlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new Mt5PlatformEntity({
              ...new Mt5PlatformEntity(),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.mt4 && (
        <MT4PlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new Mt4PlatformEntity({
              ...new Mt4PlatformEntity(),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === 'fixapi' && (
        <FixApiPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new FixApiPlatformEntity({
              ...new FixApiPlatformEntity(),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.fixapioz && (
        <FixApiPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new FixApiOZPlatformEntity({
              ...new FixApiOZPlatformEntity(),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.fixapipxm && (
        <FixApiPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new FixApiPXMPlatformEntity({
              ...new FixApiPXMPlatformEntity(),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.extapi && (
        <ExtApiPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new ExtApiPlatformEntity({
              ...new ExtApiPlatformEntity({ Type: 'extapi' }),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.CTrader && (
        <ExtApiPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new ExtApiPlatformEntity({
              ...new ExtApiPlatformEntity({ Type: 'ctrader' }),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.MatchTrade && (
        <ExtApiPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new ExtApiPlatformEntity({
              ...new ExtApiPlatformEntity({ Type: 'matchtrade' }),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
      {common.Type.value === PlatformType.mtexec && (
        <MtExecPlatformEdit
          data={{
            port: port,
            type: 'add',
            item: new MtExecPlatformEntity({
              ...new MtExecPlatformEntity(),
              ...changedObjectPart,
            }),
          }}
          isValidParent={isValid}
        />
      )}
    </>
  )
}
export default withGateway(AddPlatform)
