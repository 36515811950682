import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AggregationPoolEntity, AggregationPoolType, IExecutorsPercent, isProroptionalPool } from '../../entity/configuration'
import { addAggregationPool, changeAggregationPool, changeExecutionLpOnePreset, changeProcessing, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { buildTableHeadersFromArray } from '../../utils/table-utils'
import MultiSelectInput from '../inputs/MultiSelectInput'
import TextInput from '../inputs/TextInput'
import * as yup from 'yup'
import { useFormValidation } from '../../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, checkboxInput, sselectInput, textInput } from '../../utils/controls'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { folderNameSchema } from '../../utils/schema-utils'
import { IRightbar } from './rightbar-types'
import { RootState } from '../../redux/reducers/rootReducer'
import NewSearchableSelectInput from '../inputs/NewSearchableSelectInput'

const percentSchema = {
  LPName: yup.string().required(),
  Percent: yup.number().integer().min(0).max(100).required(),
}

function validate(obj: IExecutorsPercent) {
  return {
    LPName: !percentSchema.LPName.isValidSync(obj.LPName),
    Percent: !percentSchema.Percent.isValidSync(obj.Percent),
  }
}

function sumIsWrong(percents: any[]): boolean {
  const sum = percents.reduce((sum: number, current: any) => sum + parseFloat(current.Percent), 0)
  return +sum.toFixed(2) !== 100.0
}

function isSwitchUnsupportedType(type: AggregationPoolType): boolean {
  return !(type === AggregationPoolType.SingleBook || type === AggregationPoolType.MultiBook)
}

const schema = {
  Name: folderNameSchema(),
  Profile: yup.string().required(),
  AccumulateLpDeals: yup.boolean().required(),
  HideAggregationTimeout: yup
    .string()
    .matches(/^[0-9]+$/gi)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  Lps: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    )
    .required(),
}

const AggregationPoolRightbar: React.FC<IRightbar> = ({ data: { type, item } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation({ ...item, AggregationType: { value: item.AggregationType, label: item.AggregationType } }, schema)
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const [percentState, setPercentState] = useState(
    item.ExecutorsPercents.map((item: any) => {
      return {
        ...item,
        LPName: {
          value: item.LPName,
          label: item.LPName,
        },
      }
    }),
  )

  const dispatch = useDispatch()
  const { aggregationPools, lps, Profile, processingRules } = useSelector((state: RootState) => state.executionConfiguration)

  const [percentTouched, setPercentTouched] = useState<any>([])

  const percentErrors: any = []
  for (const percent of percentState) {
    percentErrors.push(validate(percent))
  }

  const nameLpPool = Profile.map((item: any) => item.Name)

  useEffect(() => {
    if (type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          Lps: [],
        }
      })
    }
  }, [inputState.AggregationType])

  useEffect(() => {
    if (!inputState.Profile && type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          Profile: nameLpPool[0] ?? '',
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!inputState.BBookWaitsForABook) {
      setInputState((prev: any) => {
        return {
          ...prev,
          UseABookPriceForPrimaryBBookVolume: false,
        }
      })
    }

    if (!inputState.BBookFillsLeftoverABookVolume) {
      setInputState((prev: any) => {
        return {
          ...prev,
          UseABookPriceForLeftoverVolume: false,
        }
      })
    }
  }, [inputState.BBookWaitsForABook, inputState.BBookFillsLeftoverABookVolume]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputState.AggregationType.value === AggregationPoolType.MultiBook)
      if (inputState?.Lps?.length) {
        const searchTypeLps = lps?.filter((item: any) => {
          if (inputState?.Lps?.length ? item.Name === inputState?.Lps[0]?.value : item) return item
        })
        setInputState((prev: any) => {
          return {
            ...prev,
            Lps: prev.Lps.filter((item: any) => {
              if (searchTypeLps[0].Type === 'Bbook' || searchTypeLps[0].Type === 'BbookHedged' || searchTypeLps[0].Type === 'VolumeConverter' || searchTypeLps[0].Type === 'T4BHedged') {
                return item.value !== searchTypeLps[0].Name
              } else {
                return item
              }
            }),
          }
        })
      }
  }, [inputState.AggregationType]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    isValid()

    if (inputState.AccumulateLpDeals && errors.HideAggregationTimeout) {
      return
    }

    const stateLp = percentState.map((item: any) => item.LPName.value)
    const t = gateway.Lps.filter((item: any) => {
      if (stateLp.includes(item.Name)) {
        return item.Type
      }
    })

    if (t.find((item: any) => item.Type === 'T4BHedged') && t.length > 1) {
      return
    }

    const nameLpPool = inputState.Lps.map((item: any) => item.value)
    let data: any = null
    if (inputState.AggregationType.value === AggregationPoolType.MultiProportionalBook || inputState.AggregationType.value === AggregationPoolType.ProportionalExceptClose) {
      if (errors.Name || sumIsWrong(percentState) || percentErrors.find((errItem: any) => errItem.LPName || errItem.Percent)) {
        return
      }
      data = new AggregationPoolEntity({
        ...inputState,
        Name: inputState.Name.trim(),
        ExecutorsPercents: percentState.map((item: any) => {
          return {
            ...item,
            LPName: item.LPName.value,
          }
        }),
        AggregationType: inputState.AggregationType.value,
        Lps: percentState.map((item: any) => {
          return item.LPName.value
        }),
      })
    } else {
      if (errors.Name || errors.Type || errors.Lps) {
        return
      }
      data = new AggregationPoolEntity({ ...inputState, Name: inputState.Name.trim(), AggregationType: inputState.AggregationType.value })
    }
    if (type === 'clone') {
      lps.forEach((el: any) => {
        if (nameLpPool.includes(el.Name)) {
          el.flag = true
          const lpData = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeExecutionLpOnePreset({ ...lpData, bunchProfiles: [...lpData.bunchProfiles, inputState.Profile], Profile: '' }))
        }
      })
      dispatch(addAggregationPool({ ...data, bunchProfiles: [], Profile: item.Profile }))
    } else if (type === 'add') {
      lps.forEach((el: any) => {
        if (nameLpPool.includes(el.Name)) {
          el.flag = true
          const lpData = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeExecutionLpOnePreset({ ...lpData, bunchProfiles: [...lpData.bunchProfiles], Profile: '' }))
        }
      })
      dispatch(addAggregationPool({ ...data, bunchProfiles: [], Profile: item.Profile }))
    } else {
      lps.forEach((el: any) => {
        if (nameLpPool.includes(el.Name)) {
          el.flag = true
          const lpData = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeExecutionLpOnePreset({ ...lpData, bunchProfiles: [...lpData.bunchProfiles, inputState.Profile] }))
        }
      })
      const findProcessing = processingRules.find((item: any) => item.LpPool === data?.Name)
      const findPoolNameLp = data?.Lps.map((item: any) => item.value)
      const findABBookType = gateway?.Lps.filter((item: any) => findPoolNameLp?.includes(item.Name))
      const findBbookType = findABBookType.filter((item: any) => item.Type === 'BbookHedged' || item.Type === 'VolumeConverter' || item.Type === 'Bbook' || item.Type === 'HedgingSyntheticSymbol' || item.Type === 'HedgingSyntheticIndex')
      let flag = findABBookType.length - findBbookType.length

      if (!Boolean(flag)) {
        const updateRules = findProcessing?.Rules.map((item: any) => {
          return {
            ...item,
            ExecutionPriceSource: 'LP',
          }
        })

        const newExecutionPriceSource = updateRules?.map((item: any) => item.ExecutionPriceSource)

        dispatch(changeProcessing({ ...findProcessing, Rules: updateRules, newExecutionPriceSource }))
      }

      dispatch(changeAggregationPool(data, false))
    }
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  let selectLps = null
  if (isProroptionalPool({ ...inputState, AggregationType: inputState.AggregationType.value })) {
    const usedLp = percentState.filter((item: any) => item.LPName.value).map((item: any) => item.LPName.value)
    const newLp = lps
      .filter((item: any) => {
        if (item.Type === 'HedgingSyntheticIndex' || item.Type === 'HedgingSyntheticSymbol') {
          return
        } else {
          return item
        }
      })
      .filter((item: any) => !usedLp.includes(item.Name))
    const filterDisabled = lps.filter((item: any) => !usedLp.includes(item.Name)).map((lp: any) => ({ value: lp.Name, label: lp.Type }))
    const finT4BLp: any = lps.find((item: any) => item.Name === percentState.map((item: any) => item.LPName.value)[0])

    const handlePlus = () => {
      if (newLp.length && finT4BLp?.Type !== 'T4BHedged') {
        setPercentState([...percentState, { LPName: { value: (usedLp && newLp[0]?.Name) ?? '', label: (usedLp && newLp[0]?.Name) ?? '' }, Percent: 0 }])
      } else {
        return
      }
    }

    const percentages = percentState.map((elem: any, index: number) => {
      const handleDelete = () => {
        const newState = [...percentState]
        newState.splice(index, 1)
        setPercentState(newState)
      }

      const handleStateChange = (newState: any) => {
        const newStateArr = [...percentState]
        newStateArr.splice(index, 1, newState)
        setPercentState(newStateArr)
      }

      const options = buildMultiselectOptionsFromArray(
        lps
          ?.filter((item: any) => item.Type !== 'HedgingSyntheticSymbol')
          .filter((item: any) => item.Type !== 'HedgingSyntheticIndex')
          .map((lp: any) => lp.Name) ?? [],
      ).map((item: any) => {
        if (filterDisabled.find((el: any) => el.value === item.value)) {
          return {
            ...item,
            isDisabled: false,
          }
        } else {
          return {
            ...item,
            isDisabled: true,
          }
        }
      })

      return (
        <tr key={index}>
          <td style={{ fontSize: '16px', textAlign: 'start', width: '50%' }}>
            <NewSearchableSelectInput state={percentState[index]} setState={handleStateChange} name="LPName" options={options} className="m-0" />
          </td>
          <td style={{ width: '50%' }}>
            <TextInput state={percentState[index]} setState={handleStateChange} touched={percentTouched} setTouched={setPercentTouched} errors={percentErrors[index]} name="Percent" className="m-0" />
          </td>
          <td className="h-100">
            <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </td>
        </tr>
      )
    })

    percentages.push(
      <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
        <td colSpan={3}>
          <FontAwesomeIcon icon={faPlus} />
        </td>
      </tr>,
    )

    const header = buildTableHeadersFromArray(
      ['Lp', 'Percent', 'Actions'].map((elem: string) => ({
        name: elem,
        show: true,
      })),
      'aggregation-pool',
    )

    const stateLp = percentState.map((item: any) => item.LPName.value)
    const t = gateway.Lps.filter((item: any) => {
      if (stateLp.includes(item.Name)) {
        return item.Type
      }
    })

    const table = (
      <div style={{ position: 'relative', zIndex: 3232323 }}>
        <AppTable tableHeader={header} tableData={percentages} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
        <div className={`invalid-feedback ${sumIsWrong(percentState) && 'd-block'}`}>
          <FormattedMessage id="field-validation-error-100" />
        </div>
        <div className={`invalid-feedback ${t.find((item: any) => item.Type === 'T4BHedged') && t.length > 1 && 'd-block'}`}>
          <FormattedMessage id="errorHedged" />
        </div>
      </div>
    )
    selectLps = (
      <AppAccordion
        item={{
          title: <FormattedMessage id="aggregation-pool.ExecutorsPercents" />,
          item: table,
        }}
        style={{ margin: '0 -20px', marginBottom: inputState.AggregationType.value === AggregationPoolType.MultiProportionalBook || inputState.AggregationType.value === AggregationPoolType.ProportionalExceptClose ? '' : '1rem' }}
        isHidden={false}
      />
    )
  } else {
    const searchTypeLps = lps?.filter((item: any) => {
      if (inputState?.Lps?.length ? item.Name === inputState?.Lps[0]?.value : item) return item
    })

    const optionItems = buildMultiselectOptionsFromArray(
      lps
        .filter((item: any) => {
          if (inputState?.Lps?.length) {
            if (searchTypeLps[0]?.Type === 'Bbook') {
              return item.Type === 'Bbook'
            } else if (searchTypeLps[0]?.Type === 'BbookHedged') {
              return item.Type === 'BbookHedged'
            } else if (searchTypeLps[0]?.Type === 'VolumeConverter') {
              return item?.Type === 'VolumeConverter'
            } else if (searchTypeLps[0]?.Type === 'T4BHedged') {
              return item?.Type === 'T4BHedged'
            } else if (searchTypeLps[0]?.Type === 'HedgingSyntheticIndex') {
              return item?.Type === 'HedgingSyntheticIndex'
            } else if (searchTypeLps[0]?.Type === 'HedgingSyntheticSymbol') {
              return item?.Type === 'HedgingSyntheticSymbol'
            } else {
              return item.Type !== 'BbookHedged' && item.Type !== 'Bbook' && item.Type !== 'VolumeConverter' && item?.Type !== 'T4BHedged' && item?.Type !== 'HedgingSyntheticSymbol' && item?.Type !== 'HedgingSyntheticIndex'
            }
          } else {
            return item
          }
        })
        .filter((lp: any) => {
          if (inputState.AggregationType.value === AggregationPoolType.MultiBook && lp.Type === 'VolumeConverter') {
            return null
          }
          return lp
        })
        .filter((lp: any) => {
          if (inputState.AggregationType.value === AggregationPoolType.MultiBook && lp.Type === 'Bbook') {
            return null
          }
          return lp
        })
        .filter((lp: any) => {
          if (inputState.AggregationType.value === AggregationPoolType.MultiBook && lp.Type === 'BbookHedged') {
            return null
          }
          return lp
        })
        .filter((lp: any) => {
          if (inputState.AggregationType.value !== AggregationPoolType.SingleBook && (lp.Type === 'HedgingSyntheticIndex' || lp.Type === 'HedgingSyntheticSymbol')) {
            return null
          }
          return lp
        })
        .map((lp: any) => lp.Name),
    )

    selectLps = (
      <MultiSelectInput
        state={inputState}
        setState={setInputState}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        name="Lps"
        label="aggregation-pool.ExecutorsPercents"
        options={
          (searchTypeLps[0]?.Type === 'Bbook' && inputState?.Lps[0]?.value) || (searchTypeLps[0]?.Type === 'BbookHedged' && inputState?.Lps[0]?.value) || (searchTypeLps[0]?.Type === 'VolumeConverter' && inputState?.Lps[0]?.value)
            ? [optionItems.find((item: any) => item?.value === inputState?.Lps[0]?.value)]
            : searchTypeLps[0]?.Type === 'T4BHedged' && inputState?.Lps[0]?.value
            ? []
            : (searchTypeLps[0]?.Type === 'HedgingSyntheticIndex' && inputState?.Lps[0]?.value) || (searchTypeLps[0]?.Type === 'HedgingSyntheticSymbol' && inputState?.Lps[0]?.value)
            ? [optionItems.find((item: any) => item?.value === inputState?.Lps[0]?.value)]
            : optionItems
        }
      />
    )
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && aggregationPools.find((pool: AggregationPoolEntity) => pool.Name === inputState.Name)) {
      errors.Name = true
      return 'aggregation-pool.exists'
    }
    return ''
  }

  const controls = buildControlsExtTwoPerLine(
    [
      checkboxInput('BBookWaitsForABook', true),
      checkboxInput('UseABookPriceForPrimaryBBookVolume', true).disabled(!inputState.BBookWaitsForABook),
      checkboxInput('BBookFillsLeftoverABookVolume', true),
      checkboxInput('UseABookPriceForLeftoverVolume', true).disabled(!inputState.BBookFillsLeftoverABookVolume),
    ],
    inputState,
    setInputState,
    'aggregation-pool',
    touched,
    setTouched,
    errors,
  )

  const percentLpName = percentState.map((item: any) => item.LPName.value)
  const findTypePercentLpName = lps.filter((item: any) => percentLpName.includes(item.Name))
  const lpNameNotBbook = findTypePercentLpName
    .filter((item: any) => item.Type !== 'Bbook')
    .filter((item: any) => item.Type !== 'BbookHedged')
    .filter((item: any) => item.Type !== 'VolumeConverter')

  const lpNameAbook = findTypePercentLpName.filter((item: any) => item.Type === 'Bbook' || item.Type === 'BbookHedged' || item.Type === 'VolumeConverter')
  const flagLpType = lpNameNotBbook.length && lpNameAbook.length

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`aggregation-pool.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name')
              .disabled(type === 'modify')
              .errorMessage(alreadyExist()),
            sselectInput('AggregationType', buildMultiselectOptionsFromArray(Object.values(AggregationPoolType)), false, type !== 'add'),
          ],
          inputState,
          setInputState,
          'aggregation-pool',
          touched,
          setTouched,
          errors,
        )}

        {selectLps}
        {flagLpType && (inputState.AggregationType.value === AggregationPoolType.MultiProportionalBook || inputState.AggregationType.value === AggregationPoolType.ProportionalExceptClose) ? (
          <AppAccordion
            item={{
              title: <FormattedMessage id="aggregation-pool.BbookABook" />,
              item: controls,
            }}
            style={{ margin: '0 -20px' }}
            isHidden={false}
          />
        ) : null}

        <div className="mt-2"> {buildControlsExtTwoPerLine([checkboxInput('AccumulateLpDeals'), textInput('HideAggregationTimeout').skipWhen(!inputState.AccumulateLpDeals)], inputState, setInputState, 'aggregation-pool', touched, setTouched, errors)}</div>
        {isSwitchUnsupportedType(inputState.AggregationType.value) && (
          <Alert variant="danger">
            <FormattedMessage id="aggregation-pool.unsupported-type" />
          </Alert>
        )}

        <Button className="t4b-bg-dark-button" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}

export default AggregationPoolRightbar
