import React, { FC, useEffect, useState } from 'react'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { Accordion, Alert, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../../utils/controls'
import AppButton from '../../AppButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'
// import { AppCheckboxCustom } from '../../app-checkbox-custom'
// import TextInput from '../../inputs/TextInput'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { SyntheticIndexChildrenLpBar } from './SyntheticIndexChildrenLpBar'
import {
  fetchLpsSynteticIndexLPAdd,
  fetchLpsSynteticLPIndexModify,
  fetchLpSymbolMapSyntheticIndex,
  fetchSyntheticIndexPreview,
} from '../../../redux/actions/system-settings-actions'
import { useLocation } from 'react-router-dom'
import { paginationDefault } from '../../../entity/pagination'
import { fetchGlobalSymbolMap } from '../../../redux/actions/platforms-actions'
import { hideRightBar, showRightBar } from '../../../redux/actions/rightbar-actions'
import { GlobalSymbol } from '../../../entity/system-settings'
import { transformEmptyStringMaxLength } from '../../../utils/schema-utils'
import { expToNum } from '../../../utils/num-utils'
import useDebounce from '../../../hooks/useDebounce'

export const SyntheticIndexLpBar: FC<any> = ({ data: { item, type, setLoading, params } }) => {
  const symbols = useSelector((state: any) => state.globalSymbolMap)
  const location = useLocation()
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const { data, GlobalSymbolReserved } = useSelector((state: any) => state.sysLps.SymbolMapSyntheticIndex)
  const { SyntheticIndexBid, SyntheticIndexAsk, SyntheticIndexMessage } = useSelector((state: any) => state.sysLps)

  const newSymbol = symbols?.data.map((item: any) => item.Symbol)
  const filterTypeDelete = newSymbol?.filter((item: any) => !GlobalSymbolReserved?.includes(item))
  const characterFilter = newSymbol.filter((item: any) => filterTypeDelete.includes(item))
  const buildMultiselectFromArray = buildMultiselectOptionsFromArray(characterFilter)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      Calculation: item?.Calculation?.length && item.Calculation !== 'Addition' ? item.Calculation : 'Addition',
      FinalCoefficient: String(expToNum(item.FinalCoefficient)),
      TickSize: String(expToNum(item.TickSize)),
      Digits: String(item.Digits),
      GlobalSymbolName: !item?.GlobalSymbolName ? buildMultiselectFromArray[0] : { value: item.GlobalSymbolName, label: item.GlobalSymbolName },
      Children: item?.Children?.map((elem: any) => {
        return {
          ...elem,
          MarkupBid: String(elem.MarkupBid),
          MarkupAsk: String(elem.MarkupAsk),
          LastSaveAsk: typeof elem.LastSaveBid === 'object' ? '' : String(expToNum(elem.LastSaveAsk)),
          LastSaveBid: typeof elem.LastSaveBid === 'object' ? '' : String(expToNum(elem.LastSaveBid)),
          InvalidationTimeout: String(expToNum(elem.InvalidationTimeout)),
          GlobalSymbolNameChildren: {
            value: elem?.GlobalSymbolNameChildren?.value ?? elem?.GlobalSymbolName,
            label: elem?.GlobalSymbolNameChildren?.value ?? elem?.GlobalSymbolName,
          },
          QuotesCoefficient: String(expToNum(elem.QuotesCoefficient)),
        }
      }),
    },
    {
      Digits: yup
        .string()
        .matches(/^[0-9]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0 && value <= 28),
      TickSize: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .transform(transformEmptyStringMaxLength)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
      FinalCoefficient: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => String(value) !== '0')
        .transform(transformEmptyStringMaxLength),
      Children: yup.array().of(
        yup
          .object()
          .shape({
            LastSaveAsk: yup
              .string()
              .matches(/^[0-9.]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .notRequired()
              .nullable(),

            LastSaveBid: yup
              .string()
              .matches(/^[0-9.]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .notRequired()
              .nullable(),

            FeedAggregationName: yup.string().required(),

            InvalidationTimeout: yup
              .string()
              .matches(/^[0-9]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .nullable(true)
              .required(),

            MarkupBid: yup
              .string()
              .matches(/^[-0-9]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .nullable(true)
              .required(),
            MarkupAsk: yup
              .string()
              .matches(/^[-0-9]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .nullable(true)
              .required(),

            QuotesCoefficient: yup
              .string()
              .matches(/^[0-9.]+$/gi)
              .transform(transformEmptyStringMaxLength)
              .required(),
          })
          .notRequired()
          .nullable(),
      ),
    },
  )

  const [hidden, setHidden] = useState(true)
  const [stateValid, setStateValid] = useState('')
  const [hiddenBidAsk, setHiddenBidAsk] = useState(false)
  const [summing, setSumming] = useState({
    Addition: true,
    Multiplication: false,
  })

  const [, two] = inputState?.TickSize.split('.')
  if (two?.length > inputState.Digits) {
    errors.Digits = true
    touched.Digits = true
  }

  useEffect(() => {
    setSumming(() => {
      return {
        Addition: false,
        Multiplication: false,
        [item?.Calculation]: true,
      }
    })
  }, [item.Calculation]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'add' && !item?.Children) {
      setSumming({
        Addition: true,
        Multiplication: false,
      })
      setInputState((prev: any) => {
        const newChildren = {
          FeedAggregationName: '',
          GlobalSymbolName: '',
          GlobalSymbolNameChildren: '',
          InvalidationTimeout: `${gateway.FeederInvalidationTimeout}`,
          IsInvalidation: true,
          LastSaveAsk: '',
          LastSaveBid: '',
          QuotesCoefficient: '',
          Id: Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1)),
          MarkupAsk: '0',
          MarkupBid: '0',
        }
        return {
          ...prev,
          Digits: '5',
          Children: [newChildren],
          TickSize: '',
          FinalCoefficient: '',
          Calculation: prev.Calculation,
          GlobalSymbolName: !prev.GlobalSymbolName.value ? buildMultiselectFromArray[0] : prev.GlobalSymbolName,
        }
      })
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newDigits = symbols.data.find((item: any) => item.Symbol === inputState.GlobalSymbolName?.value)

    setInputState((prev: any) => {
      return { ...prev, Digits: newDigits?.Digits }
    })
  }, [inputState.GlobalSymbolName, symbols.data]) // eslint-disable-line react-hooks/exhaustive-deps

  // const CalculationChecked = (item: any) => {
  //   setSumming((prev: any) => {
  //     if (prev?.Addition && item.Multiplication) {
  //       setInputState((prev: any) => {
  //         return {
  //           ...prev,
  //           Calculation: 'Multiplication',
  //         }
  //       })
  //       return { ...prev, ...item, Addition: false }
  //     }
  //
  //     if (prev?.Multiplication && item.Addition) {
  //       setInputState((prev: any) => {
  //         return {
  //           ...prev,
  //           Calculation: 'Addition',
  //         }
  //       })
  //       return { ...prev, ...item, Multiplication: false }
  //     }
  //
  //     if (prev?.Addition && (!item?.Addition || item?.Addition)) {
  //       return prev
  //     }
  //     if (prev?.Multiplication && (!item?.Multiplication || item?.Multiplication)) {
  //       return prev
  //     }
  //   })
  // }

  const handleSave = async () => {
    if (!isValid() || !stateValid || inputState.Children?.length === 0) {
      return
    }

    const body = {
      Id: inputState.Id,
      Description: inputState.Description,
      GlobalSymbolName: inputState.GlobalSymbolName?.value,
      Digits: inputState.Digits,
      TickSize: inputState.TickSize,
      Calculation: inputState.Calculation,
      FinalCoefficient: inputState.FinalCoefficient,
      Children: inputState.Children.map((item: any) => {
        return {
          MarkupAsk: Number(item.MarkupAsk),
          MarkupBid: Number(item.MarkupBid),
          Digits: item.Digits,
          FeedAggregationName: item.FeedAggregationName.value,
          GlobalSymbolName: item.GlobalSymbolNameChildren?.value,
          LastSaveBid: item.LastSaveBid,
          LastSaveAsk: item.LastSaveAsk,
          InvalidationTimeout: item.InvalidationTimeout,
          IsInvalidation: item.IsInvalidation,
          QuotesCoefficient: item.QuotesCoefficient,
        }
      }),
    }

    if (type === 'add') {
      await dispatch(
        fetchLpsSynteticIndexLPAdd({
          action: '',
          params: {
            Gateway: gateway.Name,
            lp: location.search.slice(4).replace('%20', ' '),
          },
          body: body,
        }),
      )
    } else {
      await dispatch(
        fetchLpsSynteticLPIndexModify({
          action: '',
          params: {
            Gateway: gateway.Name,
            lp: location.search.slice(4).replace('%20', ' '),
          },
          body: body,
        }),
      )
    }
    dispatch(hideRightBar())
    dispatch(
      fetchLpSymbolMapSyntheticIndex({
        Gateway: gateway.Name,
        field: '',
        by: '',
        Page: paginationDefault.Page,
        Pages: paginationDefault.Pages,
        Count: paginationDefault.Count,
        lp: location.search.slice(4).replace('%20', ' '),
        setLoading: setLoading,
        ...params,
      }),
    )
    dispatch(
      fetchGlobalSymbolMap({
        ...inputState,
        Gateway: gateway.Name,
        Page: paginationDefault.Page,
        Count: 1000000,
        field: 'Symbol',
        by: 'asc',
      }),
    )
  }

  const handelAdd = () => {
    const newChildren = {
      FeedAggregationName: '',
      GlobalSymbolName: '',
      GlobalSymbolNameChildren: '',
      InvalidationTimeout: `${gateway.FeederInvalidationTimeout}`,
      IsInvalidation: true,
      LastSaveAsk: '',
      LastSaveBid: '',
      MarkupAsk: '0',
      MarkupBid: '0',
      QuotesCoefficient: '',
      Id: Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1)),
    }

    setInputState((prev: any) => {
      return {
        ...prev,
        Children: [...prev.Children, newChildren],
      }
    })
  }

  const addNewGlobalSymbol = () => {
    dispatch(
      showRightBar('globalSymbolMap', {
        type: 'add',
        params: {
          Gateway: gateway.Name,
          Page: paginationDefault.Page,
          Count: 1000000,
          field: '',
          by: '',
          Lps: gateway.Lps,
        },
        item: new GlobalSymbol(),
        SyntIndex: (params: any) => {
          const findGlobal = symbols?.data?.filter((item: any) => item.Symbol === params?.SyntIndexState?.GlobalSymbolName)
          const findSymbolOfGlobal = data?.filter((item: any) => item.GlobalSymbolName === params?.SyntIndexState?.GlobalSymbolName) // false

          const body = {
            ...params.SyntIndexState,
            GlobalSymbolName: findGlobal.length && findSymbolOfGlobal.length ? null : params?.SyntIndexState?.GlobalSymbolName,
          }

          dispatch(showRightBar('SyntheticIndexLpBar', { type: params.type, item: body }))
        },
        flagIndex: true,
        SyntIndexState: inputState,
      }),
    )
  }

  const createFormulaIndexAsk = useDebounce(
    inputState?.Children?.reduce((acc: any, item: any) => {
      let tmp = ''
      let digits = 10 ** (+item.Digits * -1)
      let MarkupAsk = +item.MarkupAsk * digits > 0 ? Number(+item.MarkupAsk * digits).toFixed(item.Digits) : `(${Number(+item.MarkupAsk * digits).toFixed(item.Digits)})`

      if (inputState?.Calculation === 'Multiplication') {
        tmp = `(${item.GlobalSymbolNameChildren?.value}+${MarkupAsk})*${item.QuotesCoefficient} * `
      } else {
        tmp = `(${item.GlobalSymbolNameChildren?.value}+${MarkupAsk})*${item.QuotesCoefficient} + `
      }

      return (acc += tmp)
    }, ''),
    500,
  )

  const createFormulaIndexBid = useDebounce(
    inputState?.Children?.reduce((acc: any, item: any) => {
      let tmp = ''
      let digits = 10 ** (+item.Digits * -1)
      let MarkupBid = +item.MarkupBid * digits > 0 ? Number(+item.MarkupBid * digits).toFixed(item.Digits) : `(${Number(+item.MarkupBid * digits).toFixed(item.Digits)})`

      if (inputState?.Calculation === 'Multiplication') {
        tmp = `(${item.GlobalSymbolNameChildren?.value}-${MarkupBid})*${item.QuotesCoefficient} * `
      } else {
        tmp = `(${item.GlobalSymbolNameChildren?.value}-${MarkupBid})*${item.QuotesCoefficient} + `
      }

      return (acc += tmp)
    }, ''),
    500,
  )

  const withTooltipPreventOverflow = (item: any, text: string, key: any) => {
    const overlay = (
      <Tooltip id={`tooltip-${key}`}>
        <FormattedMessage id={text} />
      </Tooltip>
    )
    return (
      <OverlayTrigger key={key} overlay={overlay} placement="top">
        {item}
      </OverlayTrigger>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="color-dark font-500">
          <FormattedMessage id={type === 'add' ? 'SynteticIndex.rightbar.add' : 'SynteticIndex.rightbar.modify'} />
        </Card.Header>
        <Card.Body>
          <div className="wrp-pos">
            {buildControlsExtTwoPerLine(
              [sselectInput('GlobalSymbolName', buildMultiselectFromArray), textInput('Description'), textInput('TickSize'), textInput('Digits'), textInput('FinalCoefficient')],
              inputState,
              setInputState,
              'SyntheticIndex',
              touched,
              setTouched,
              errors,
            )}
            {/*<div className="p-0" style={{ width: 366 }}>*/}
            {/*  <TextInput*/}
            {/*    state={inputState}*/}
            {/*    setState={setInputState}*/}
            {/*    name="FinalCoefficient"*/}
            {/*    label="SyntheticIndex.FinalCoefficient"*/}
            {/*    setTouched={setTouched}*/}
            {/*    touched={touched}*/}
            {/*    errors={errors}*/}
            {/*    isDisabled={inputState.IsInvalidation === false}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="d-flex align-items-center justify-content-between m-0 p-0 col-12 mb-3">
              {/*<div className="d-flex flex-column mb-3" style={{ width: 366 }}>*/}
              {/*  <div className="col-6 mb-1 mt-1">Way of calculation:</div>*/}
              {/*  <div className="d-flex col-6 p-2 align-items-center text-center">*/}
              {/*    <div className="mr-5">*/}
              {/*      <AppCheckboxCustom*/}
              {/*        size={'28px'}*/}
              {/*        text={'Addition'}*/}
              {/*        borderColor={'#2A5B88'}*/}
              {/*        color={'#2A5B88'}*/}
              {/*        sizeChecked={'20px'}*/}
              {/*        checked={summing.Addition}*/}
              {/*        onChange={CalculationChecked}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <AppCheckboxCustom*/}
              {/*      size={'28px'}*/}
              {/*      text={'Multiplication'}*/}
              {/*      borderColor={'#2A5B88'}*/}
              {/*      color={'#2A5B88'}*/}
              {/*      sizeChecked={'20px'}*/}
              {/*      checked={summing.Multiplication}*/}
              {/*      onChange={CalculationChecked}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="wrp-pos-btn">
              <AppButton variant="add-global-symbol" onClick={addNewGlobalSymbol} className="ml-auto mr-2  align-items-center" />
            </div>
          </div>
          <Accordion defaultActiveKey={hidden ? 'accordion' : undefined}>
            <Card className={`accordion-custom border-none`} style={{ overflow: 'visible' }}>
              <Card.Header className="d-flex justify-content-between p-0 accordion-custom__toggle border-none wordBreak" onClick={() => setHidden(prev => !prev)}>
                <Accordion.Toggle
                  eventKey="accordion"
                  className={`cursor-pointer d-flex align-items-center border-none font-500 flex-grow-1 text-wrap accordion-header-custom`}
                  as="div"
                >
                  <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>Source symbols</div>
                    <FontAwesomeIcon icon={faAngleRight} className={hidden ? 'icon ml-auto' : 'icon icon-rotate ml-auto'} />
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="accordion">
                <Card.Body className="accordion-custom__collapse accordion-collapse-color">
                  {inputState?.Children?.map((elem: any, index: number) => {
                    elem.Id = elem.Id ? elem.Id : Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1))

                    return (
                      <div key={elem.Id} className="d-flex align-items-center justify-content-center">
                        <SyntheticIndexChildrenLpBar
                          item={elem}
                          originItem={item}
                          symbol={inputState?.GlobalSymbolName}
                          data={inputState?.Children}
                          index={index}
                          setStateValid={setStateValid}
                          setStateIndex={setInputState}
                          indexValid={isValid}
                          type={type}
                        />
                      </div>
                    )
                  })}
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="cursor-pointer" onClick={handelAdd}>
                      <span className="color-checked color-checked-hov">Add symbol</span>
                      <FontAwesomeIcon icon={faPlus} className=" ml-2 cursor-pointer" />
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <div className="mb-4">
            Total symbols: <span className="color-checked">{inputState?.Children?.length}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <Button
              disabled={inputState?.Children?.length === 0}
              className="mr-3 btn-field-index"
              onClick={() => {
                setHiddenBidAsk(true)
                dispatch(
                  fetchSyntheticIndexPreview({
                    action: '',
                    params: {
                      Gateway: gateway.Name,
                    },
                    body: {
                      Digits: inputState.Digits,
                      GlobalSymbolName: inputState.GlobalSymbolName?.value,
                      TickSize: inputState.TickSize,
                      Calculation: inputState.Calculation,
                      FinalCoefficient: inputState.FinalCoefficient,
                      Children: inputState.Children.map((item: any) => {
                        return {
                          MarkupAsk: Number(item.MarkupAsk),
                          MarkupBid: Number(item.MarkupBid),
                          Digits: item.Digits,
                          FeedAggregationName: item.FeedAggregationName.value,
                          GlobalSymbolName: item.GlobalSymbolNameChildren?.value,
                          QuotesCoefficient: item.QuotesCoefficient,
                        }
                      }),
                    },
                  }),
                )
              }}
            >
              <FormattedMessage id="SyntheticIndex" />
            </Button>
            {hiddenBidAsk && (
              <div className="d-flex align-items-center justify-content-between text-center ">
                <div className="name-quote">Bid:</div>
                <div className="mr-3 blockQuote border">
                  {`${SyntheticIndexBid}`.length >= 17 ? (
                    withTooltipPreventOverflow(<div className="text-quote">{typeof SyntheticIndexBid === 'object' ? '0' : SyntheticIndexBid}</div>, `${SyntheticIndexBid}`, 'bid')
                  ) : (
                    <div className="text-quote">{typeof SyntheticIndexBid === 'object' ? '0' : SyntheticIndexBid}</div>
                  )}
                </div>
                <div className="name-quote">Ask:</div>
                <div className="blockQuote border">
                  {`${SyntheticIndexAsk}`.length >= 17 ? (
                    withTooltipPreventOverflow(<div className="text-quote">{typeof SyntheticIndexAsk === 'object' ? '0' : SyntheticIndexAsk}</div>, `${SyntheticIndexAsk}`, 'ask')
                  ) : (
                    <div className="text-quote">{typeof SyntheticIndexAsk === 'object' ? '0' : SyntheticIndexAsk}</div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            {SyntheticIndexMessage && (
              <Alert className="d-flex mb-0" variant="danger">
                <FormattedMessage id={SyntheticIndexMessage} />
              </Alert>
            )}
          </div>
          <div className="mt-4 ">
            <span className="color-checked">Index summary:</span>
            <div className="wpr-formula border">
              <div className="blockFormula d-flex align-items-center">
                <div>
                  <div>
                    Bid: ({createFormulaIndexBid?.slice(0, -3)}) * {inputState.FinalCoefficient}
                  </div>
                  <div>
                    Ask: ({createFormulaIndexAsk?.slice(0, -3)}) * {inputState.FinalCoefficient}
                  </div>
                  <div className="color-formula mt-2">
                    Quotes are casted to {inputState.TickSize} tick size step and {inputState.Digits} digits
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-4">
            {inputState?.Children?.length === 0 && (
              <Alert className="d-flex mb-0" variant="danger">
                <FormattedMessage id={'Set source symbols before saving'} />
              </Alert>
            )}
          </div>
          <Button className="t4b-bg-dark-button mt-3" onClick={handleSave} disabled={inputState?.Children?.length === 0}>
            <FormattedMessage id="save" />
          </Button>
        </Card.Body>
      </Card>
    </>
  )
}
