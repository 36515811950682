import React, { FC } from 'react'
import * as yup from 'yup'
import { IRightbar } from '../rightbar-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { AppAccordion } from '@t4b/core'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { buildControlsExtTwoPerLine, checkboxInput, sselectInput, textInput } from '../../../utils/controls'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'
import { IMultiSelectItem } from '../../../entity/multiselect'
import { addCloneNotificationReceiverFetch, NotificationChannelFetch, NotificationReceiverFetch, updateNotificationReceiverFetch } from '../../monitoring/NotificationPages/NotificationReceiverSettings/actions'
import { alreadyExistNameNotification } from '../lpRightbarUtils/utils'

class Receiver {
  Name: string
  Id: number
  Type: IMultiSelectItem

  constructor(item?: any) {
    this.Id = item.Id ?? null
    this.Name = item?.Name ?? ''
    this.Type = item?.Type
  }
}

class ReceiverType {
  Id: number
  Enabled: boolean
  Destinations: string[]
  Host: number | string
  Port: number | string
  Login: number | string
  Password: number | string
  Phone: number | string

  constructor(item?: any) {
    this.Id = item?.Id ?? -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    this.Enabled = item.Enabled ?? false
    this.Destinations = item.Destinations ?? []
    this.Host = item.Host ?? ''
    this.Port = item.Port ?? null
    this.Login = item.Login ?? ''
    this.Password = item.Password ?? ''
    this.Phone = item.Phone ?? ''
  }
}

const validSchema = (type: any) => {
  if (type === 'Email') {
    return {
      Destinations: yup.string().required(),
      Host: yup.string().required(),
      Port: yup.string().matches(/^[0-9]+$/gi),
      Login: yup.string().required(),
      Password: yup.string().required(),
    }
  }
  if (type === 'SMSTwilio') {
    return {
      Destinations: yup.string().required(),
      Phone: yup.string().matches(/^[+0-9]+$/gi),
      Login: yup.string().required(),
      Password: yup.string().required(),
    }
  }
  if (type === 'TelegramBot') {
    return {
      Destinations: yup.string().required(),
      Password: yup.string().required(),
    }
  }
}
const NotificationReceiverRightbar: FC<IRightbar> = ({ data: { type, item, setLoading, Gateway } }) => {
  const dispatch = useDispatch()
  const { receiver } = useSelector((state: any) => state.NotificationReducer)

  const [inputStateOne, setInputStateOne, touchedOne, setTouchedOne, errorsOne, isValidOne] = useFormValidation(
    new Receiver({
      Name: item?.Name,
      Type: { value: item?.Type ?? 'Email', label: item?.Type ?? 'Email' },
      Id: item.Id,
    }),
    { Name: yup.string().required() },
  )

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ReceiverType(item), validSchema(inputStateOne.Type.value))

  const inputsOne = buildControlsExtTwoPerLine(
    [textInput('Name').errorMessage(alreadyExistNameNotification(type, receiver, inputStateOne, errorsOne)), sselectInput('Type', buildMultiselectOptionsFromArray(['Email', 'SMSTwilio', 'TelegramBot']))],
    inputStateOne,
    setInputStateOne,
    'ReceiverOne',
    touchedOne,
    setTouchedOne,
    errorsOne,
  )

  const inputsEmail = buildControlsExtTwoPerLine(
    [textInput('Destinations', false, '', '', 'Comma-separated list', true), textInput('Host'), textInput('Port'), textInput('Login'), textInput('Password'), checkboxInput('Enabled')],
    inputState,
    setInputState,
    'ReceiverEmail',
    touched,
    setTouched,
    errors,
  )

  const inputsSms = buildControlsExtTwoPerLine(
    [textInput('Phone'), textInput('Destinations', false, '', '', 'Comma-separated list', true), textInput('Login'), textInput('Password'), checkboxInput('Enabled')],
    inputState,
    setInputState,
    'ReceiverSms',
    touched,
    setTouched,
    errors,
  )

  const inputsTelegram = buildControlsExtTwoPerLine([textInput('Destinations', false, '', '', 'Comma-separated list', true), textInput('Password'), checkboxInput('Enabled')], inputState, setInputState, 'ReceiverTelegram', touched, setTouched, errors)

  const choiceInput = () => {
    if (inputStateOne?.Type.value === 'Email') return inputsEmail
    if (inputStateOne?.Type.value === 'SMSTwilio') return inputsSms
    if (inputStateOne?.Type.value === 'TelegramBot') return inputsTelegram
  }

  const handleSave = async () => {
    const cond1 = !isValid()
    const cond2 = !isValidOne()

    if (cond1 || cond2) {
      return
    }

    if (type === 'clone') {
      await addCloneNotificationReceiverFetch({
        body: { ...inputStateOne, ...inputState, Type: inputStateOne.Type.value },
        dispatch,
        setLoading,
        Gateway,
      })
      await NotificationChannelFetch({ Gateway: Gateway, dispatch })
      NotificationReceiverFetch({
        setLoading,
        Gateway: Gateway,
        dispatch,
        Page: 1,
        Count: 10000,
      })
    }

    if (type === 'add') {
      await addCloneNotificationReceiverFetch({
        body: { ...inputStateOne, ...inputState, Type: inputStateOne.Type.value, Destinations: Array.isArray(inputState.Destinations) ? inputState.Destinations : inputState.Destinations.split(',') },
        dispatch,
        setLoading,
        Gateway,
      })
      await NotificationChannelFetch({ Gateway: Gateway, dispatch })
      await NotificationReceiverFetch({
        setLoading,
        Gateway: Gateway,
        dispatch,
        Page: 1,
        Count: 10000,
      })
    }

    if (type === 'modify') {
      await updateNotificationReceiverFetch({
        body: { ...inputStateOne, ...inputState, Type: inputStateOne.Type.value, Destinations: Array.isArray(inputState.Destinations) ? inputState.Destinations : inputState.Destinations.split(',') },
        dispatch,
        setLoading,
        Gateway,
      })

      await NotificationChannelFetch({ Gateway: Gateway, dispatch })
      await NotificationReceiverFetch({
        setLoading,
        Gateway: Gateway,
        dispatch,
        Page: 1,
        Count: 10000,
      })
      // dispatch(updateNotificationReceiver({ ...inputStateOne, ...inputState, Type: inputStateOne.Type.value, Destinations: Array.isArray(inputState.Destinations) ? inputState.Destinations : inputState.Destinations.split(',') }))
    }

    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`Receiver.${type}`} />,
          item: (
            <div>
              {inputsOne}
              {choiceInput()}
            </div>
          ),
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default NotificationReceiverRightbar
