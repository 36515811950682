import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { PlatformType, Session } from '../../entity/platforms'
import { GatewayEntity } from '../../entity/system-settings'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchPlatformSessionsUpdate, onlyFetchPlatformAccountsNames, onlyFetchPlatformGroupsNames, onlyFetchSendercompTargetcomp } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, cmselectInput, sselectInput, textInput } from '../../utils/controls'
import { processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray, buildSelectOption, convertSaveQuotesDepth, isTrimString, optionsToStrings } from '../../utils/multiselect-utils'

interface ISessionsRightbar extends IGatewayProp {
  data: any
}

function getSessionSchema(gateway: GatewayEntity, params: { Platform: string; Type: 'string' }) {
  if (gateway && params) {
    const platform = gateway.Platforms.find(item => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mt4) {
      return Session.schemaMt4
    }

    if (platform && platform.Type === PlatformType.fixapi) {
      return Session.schemaFixApi
    }

    if (platform && platform.Type === PlatformType.extapi) {
      return Session.schemaExtApi
    }

    if (platform && platform.Type === PlatformType.CTrader) {
      return Session.schemaExtApi
    }

    if (platform && platform.Type === PlatformType.MatchTrade) {
      return Session.schemaExtApi
    }

    if (platform && platform.Type === PlatformType.fixapioz) {
      return Session.schemaFixapioz
    }

    if (platform && platform.Type === PlatformType.fixapipxm) {
      return Session.schemaFixapioz
    }
  }
  return Session.schema
}

function isExtApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.extapi) {
      return true
    }
  }
  return false
}

function isCtradeApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.CTrader) {
      return true
    }
  }
  return false
}

function isMatchTradeApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.MatchTrade) {
      return true
    }
  }
  return false
}

function isOZPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.fixapioz) {
      return true
    }
  }
  return false
}

function isMT4ApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mt4) {
      return true
    }
  }
  return false
}

function isMT5ApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mt5) {
      return true
    }
  }
  return false
}

function isFixApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.fixapi) {
      return true
    }
  }
  return false
}

function coverage(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mtexec) {
      return true
    }
  }
  return false
}

const SessionsRightbar: React.FC<ISessionsRightbar> = React.memo(({ data: { type, item, params }, gateway }) => {
  const dispatch = useDispatch()

  const ref = useRef<any>(null)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation({ ...item, IgnoreLogOptions: item.IgnoreLogOptions ? buildSelectOption(item.IgnoreLogOptions) : buildSelectOption('Quote') }, getSessionSchema(gateway, params))
  const [accounts, setAccounts] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const [sendercompTargetcompId, setSendercompTargetcompId] = useState([{ SenderCompId: '', TargetCompId: '' }])

  useEffect(() => {
    if (isFixApiPlatform(gateway, params)) {
      onlyFetchPlatformAccountsNames({ ...params })
        .then((data: any) => setAccounts(buildMultiselectOptionsFromArray(data)))
        .catch((error: Error) => processError(error, dispatch))
      onlyFetchPlatformGroupsNames({ ...params })
        .then((data: any) => setGroups(buildMultiselectOptionsFromArray(data)))
        .catch((error: Error) => processError(error, dispatch))
    }
    onlyFetchSendercompTargetcomp({ ...params })
      .then((data: any) => setSendercompTargetcompId(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, gateway, dispatch])

  useEffect(() => {
    if (type === 'modify') {
      setInputState((prev: any) => {
        return {
          ...prev,
          IgnoreLogOptions: prev.IgnoreLogOptions,
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        SaveQuotesDepth: prev.SaveQuotesDepth === 0 ? { value: 0, label: 'Nothing' } : { value: prev.SaveQuotesDepth, label: prev.SaveQuotesDepth },
        T4bFixProtocolVersion: prev.T4bFixProtocolVersion === 'Fix31' ? { value: 'Fix31', label: 'FIXAPI 3.1' } : { value: 'Fix32', label: 'FIXAPI 3.2' },
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const t4bProtocol = [
    { value: 'Fix31', label: 'FIXAPI 3.1' },
    { value: 'Fix32', label: 'FIXAPI 3.2' },
  ]

  const alreadyExist = () => {
    if (type === 'add') {
      for (const item of sendercompTargetcompId) {
        if (item?.SenderCompId === inputState.SenderCompId && item?.TargetCompId === inputState.TargetCompId && inputState.SenderCompId.length !== 0 && inputState.TargetCompId.length !== 0) {
          errors.SenderCompId = true
          errors.TargetCompId = true
          return 'Аlready exists'
        }
      }

      if (inputState.SenderCompId === inputState.TargetCompId) {
        errors.SenderCompId = true
        errors.TargetCompId = true
        return 'Incorect value'
      }
    }
    return ''
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId')
        .disabled(type === 'modify')
        .errorMessage(alreadyExist()),
      textInput('TargetCompId')
        .disabled(type === 'modify')
        .errorMessage(alreadyExist()),
      textInput('BeginString').disabled(type === 'modify'),
      textInput('Username').skipWhen(isOZPlatform(gateway, params)),
      textInput('Password'),
      textInput('DefaultAccount').skipWhen(isExtApiPlatform(gateway, params)).skipWhen(isCtradeApiPlatform(gateway, params)).skipWhen(isMatchTradeApiPlatform(gateway, params)).skipWhen(isMT4ApiPlatform(gateway, params)),
      sselectInput('T4bFixProtocolVersion', t4bProtocol).skipWhen(isMT4ApiPlatform(gateway, params)),
      sselectInput(
        'SaveQuotesDepth',
        buildMultiselectOptionsFromArray(convertSaveQuotesDepth([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])),
        true,
        false,
        null,
        null,
        !gateway.StatConnectionStringFilled,
        'To save quotes, set valid credentials in root DB: `stat_connection_string`.',
      )
        .disabled(!gateway.StatConnectionStringFilled)
        .skipWhen(isExtApiPlatform(gateway, params))
        .skipWhen(isCtradeApiPlatform(gateway, params))
        .skipWhen(isMatchTradeApiPlatform(gateway, params))
        .skipWhen(isMT4ApiPlatform(gateway, params))
        .skipWhen(coverage(gateway, params)),
      cmselectInput('Accounts', accounts, '', 'Mask', true)
        .skipWhen(isExtApiPlatform(gateway, params))
        .skipWhen(isCtradeApiPlatform(gateway, params))
        .skipWhen(isMatchTradeApiPlatform(gateway, params))
        .skipWhen(isMT4ApiPlatform(gateway, params))
        .title('Add mask:')
        .skipWhen(coverage(gateway, params)),
      cmselectInput('Groups', groups, '', 'Mask', true)
        .skipWhen(isExtApiPlatform(gateway, params))
        .skipWhen(isCtradeApiPlatform(gateway, params))
        .skipWhen(isMatchTradeApiPlatform(gateway, params))
        .skipWhen(isMT4ApiPlatform(gateway, params))
        .title('Add mask:')
        .skipWhen(coverage(gateway, params)),
      sselectInput('IgnoreLogOptions', buildMultiselectOptionsFromArray(['Nothing', 'Quote', 'Other']))
        .optionZindex(false)
        .skipWhen(isMT5ApiPlatform(gateway, params)),
      checkboxInput('SendPositionID').skipWhen(!coverage(gateway, params)),
      checkboxInput('FeedOnly').skipWhen(isMT4ApiPlatform(gateway, params)).skipWhen(isMT5ApiPlatform(gateway, params)),
    ],
    inputState,
    setInputState,
    coverage(gateway, params) ? 'coverage.sessions' : 'sessions',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    if (!isValid()) {
      return
    }

    const body = { ...inputState }
    body.Accounts = coverage(gateway, params) ? [isTrimString(inputState.DefaultAccount)] : isTrimString(optionsToStrings(body.Accounts))
    body.Groups = isTrimString(optionsToStrings(body.Groups))

    dispatch(
      fetchPlatformSessionsUpdate({
        action: type,
        params: { ...params },
        body: {
          ...body,
          SaveQuotesDepth: inputState.SaveQuotesDepth.value,
          IgnoreLogOptions: inputState.IgnoreLogOptions.value,
          T4bFixProtocolVersion: inputState.T4bFixProtocolVersion.value,
        },
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`sessions.${type}`} />,
          item: inputs,
        }}
        ref={ref}
        render={ref.current}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default withGateway(SessionsRightbar)
