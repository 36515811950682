import { asMs, asTime, asTimeT } from '../utils/table-utils'
import { ITableColumn } from '../utils/table-types'

export const mt4DealsTableHeader: ITableColumn[] = [
  { name: 'OrderId', show: true },
  { name: 'UserId', show: true },
  { name: 'UserName', show: false },
  { name: 'UserGroup', show: false },
  { name: 'Direction', show: true },
  { name: 'RequestInitiator', show: false },
  { name: 'Symbol', show: true },
  { name: 'Comment', show: false },
  { name: 'OpenRequestTime', format: asTime, show: false },
  { name: 'CloseRequestTime', format: asTime, show: false },
  { name: 'Volume', show: true, sortable: false },
  { name: 'LotVolume', show: true },
  { name: 'OpenTime', format: asTime, show: true },
  { name: 'CloseTime', format: asTime, show: true },
  { name: 'OpenRequestPrice', show: false },
  { name: 'CloseRequestPrice', show: false },
  { name: 'OpenPrice', show: true },
  { name: 'ClosePrice', show: true },
  { name: 'OpenSlippage', show: false },
  { name: 'CloseSlippage', show: false },
  { name: 'StopLoss', show: false },
  { name: 'TakeProfit', show: false },
  { name: 'TraderProfit', show: true, sortable: false },
  { name: 'ClosebyOrder', show: false },
  { name: 'Commission', show: false },
  { name: 'TpPriceAdjustment', show: false, sortable: false },
  { name: 'OpenAggregationPool', show: false },
  { name: 'CloseAggregationPool', show: false },
  { name: 'OpenLpExecutionTime', show: false, format: asMs, sortable: false },
  { name: 'OpenFullExecutionTime', show: false, format: asMs },
  { name: 'CloseLpExecutionTime', show: false, format: asMs, sortable: false },
  {
    name: 'CloseFullExecutionTime',
    show: false,
    format: asMs,
  },
  { name: 'OpenLpPriceSlippage', show: false, sortable: false },
  { name: 'CloseLpPriceSlippage', show: false, sortable: false },
  { name: 'OpenTpMarkup', show: false, sortable: false },
  { name: 'CloseTpMarkup', show: false, sortable: false },
  { name: 'OpenTpBrokerProfit', show: false, sortable: false },
  { name: 'CloseTpBrokerProfit', show: false, sortable: false },
  { name: 'OpenTotalBrokerProfit', show: false, sortable: false },
  { name: 'CloseTotalBrokerProfit', show: false, sortable: false },
  { name: 'OpenPlatformPriceSlippage', show: false, sortable: false },
  { name: 'ClosePlatformPriceSlippage', show: false, sortable: false },
  /////////////////
  { name: 'OpenRequestPrice', show: false, sortable: true },
  { name: 'CloseRequestPrice', show: false, sortable: true },
  { name: 'OpenProcessingRuleName', show: false, sortable: false },
  { name: 'CloseProcessingRuleName', show: false, sortable: false },
  { name: 'OpenMarkupPips', show: false, sortable: false },
  { name: 'CloseMarkupPips', show: false, sortable: false },
  { name: 'OpenMarkupUsd', show: false, sortable: false },
  { name: 'CloseMarkupUsd', show: false, sortable: false },
  { name: 'OpenLpExecutionPrice', show: false, sortable: true },
  { name: 'CloseLpExecutionPrice', show: false, sortable: true },
  { name: 'OpenUsdConversionRate', show: false, sortable: true },
  { name: 'CloseUsdConversionRate', show: false, sortable: true },
]

export const mt5DealsTableHeader: ITableColumn[] = [
  { name: 'Deal', show: true },
  { name: 'Login', show: true },
  { name: 'Group', show: false },
  { name: 'Order', show: false },
  { name: 'Action', show: true },
  { name: 'Entry', show: true },
  { name: 'Reason', show: false },
  { name: 'Time', format: asTime, show: true },
  { name: 'Symbol', show: true },
  { name: 'Price', show: true },
  { name: 'Volume', show: true },
  { name: 'LotVolume', show: true },
  { name: 'Profit', show: true, sortable: false },
  { name: 'Storage', show: false },
  { name: 'Commission', show: false },
  { name: 'ExpertId', show: false },
  { name: 'PositionId', show: false },
  { name: 'Comment', show: false },
  { name: 'OpenPrice', show: false },
  { name: 'GatewayPrice', show: false },
  { name: 'AggregationPool', show: false },
  { name: 'TpPriceAdjustment', show: false, sortable: false },
  { name: 'BrokerProfit', show: false },
  { name: 'Slippage', show: false },
  { name: 'LpExecutionTime', show: false, format: asMs },
  { name: 'FullExecutionTime', show: false, format: asMs, sortable: false },
  { name: 'LpPriceSlippage', show: false, sortable: false },
  { name: 'PlatformPriceSlippage', show: false, sortable: false },
  { name: 'TpMarkup', show: false, sortable: false },
  { name: 'TotalMarkup', show: false, sortable: false },
  { name: 'TpBrokerProfit', show: false, sortable: false },
  { name: 'TotalBrokerProfit', show: false, sortable: false },
  /////////////////
  { name: 'ProcessingRuleName', show: false, sortable: true },
  { name: 'PlatformRequestPrice', show: false, sortable: true },
  { name: 'MarkupPips', show: false, sortable: false },
  { name: 'MarkupUsd', show: false, sortable: false },
  { name: 'GatewayPrice', show: false, sortable: true },
  { name: 'UsdConversionRate', show: false, sortable: true },
]

export const formatNull = (item: any) => {
  if (item === 0 || item === '0' || typeof item === 'object' || item === '') {
    return 'N/A'
  } else {
    return item
  }
}

const formatAccountNull = (item: any) => {
  if (typeof item === 'object') {
    return 'N/A'
  } else {
    return item
  }
}

const formatStringForNull = (item: any) => {
  if (item === '') {
    return 'N/A'
  } else {
    return item
  }
}

export const aggregation: ITableColumn[] = [
  { name: 'Lp', show: true, sortable: true },
  { name: 'ExecutionId', show: true, sortable: true, format: formatNull },
  { name: 'DealId', show: true, sortable: true },
  { name: 'Account', show: true, sortable: true, format: formatAccountNull },
  { name: 'Side', show: true, sortable: true },
  { name: 'ExecutionTime', show: true, sortable: false },
  { name: 'Symbol', show: true, sortable: false },
  { name: 'LpPrice', show: true, sortable: true },
  { name: 'LastVolume', show: true, sortable: true },
  { name: 'LotLastVolume', show: false, sortable: false },
  { name: 'ClientOrderId', show: false, sortable: true },
  { name: 'OrderVolume', show: false, sortable: false },
  { name: 'FilledVolume', show: false, sortable: true },
  { name: 'TransactionTime', show: false, sortable: true, format: asTimeT },
  { name: 'SecondaryClientExecId', show: false, sortable: true, format: formatNull },
]

export const aggregationMT4: ITableColumn[] = [
  { name: 'Lp', show: true, sortable: true },
  { name: 'ExecutionId', show: true, sortable: true, format: formatNull },
  { name: 'Mt4OrderId', show: true, sortable: false },
  { name: 'Account', show: true, sortable: true, format: formatStringForNull },
  { name: 'Side', show: true, sortable: true },
  { name: 'ExecutionTime', show: true, sortable: false },
  { name: 'Symbol', show: true, sortable: false },
  { name: 'LpPrice', show: true, sortable: true },
  { name: 'LastVolume', show: true, sortable: true },
  { name: 'LotLastVolume', show: false, sortable: false },
  { name: 'ClientOrderId', show: false, sortable: true },
  { name: 'OrderVolume', show: false, sortable: false },
  { name: 'FilledVolume', show: false, sortable: true },
  { name: 'TransactionTime', show: false, sortable: true, format: asTimeT },
  { name: 'SecondaryClientExecId', show: false, sortable: true, format: formatNull },
]

export const aggregationFix: ITableColumn[] = [
  { name: 'Lp', show: true, sortable: true },
  { name: 'ExecutionId', show: true, sortable: true, format: formatNull },
  { name: 'Account', show: true, sortable: true, format: formatAccountNull },
  { name: 'Side', show: true, sortable: true },
  { name: 'ExecutionTime', show: true, sortable: true },
  { name: 'PlatformSymbol', show: true, sortable: true },
  { name: 'LpPrice', show: true, sortable: true },
  { name: 'LastVolume', show: true, sortable: true },
  { name: 'LotLastVolume', show: false, sortable: false },
  { name: 'AvgPrice', show: false, sortable: true },
  { name: 'ExecutionReportId', show: false, sortable: true },
  { name: 'LotOrderVolume', show: false, sortable: false },
  { name: 'SecondaryExecutionId', show: false, sortable: true, format: formatNull },
  { name: 'ClientOrderId', show: false, sortable: true },
  { name: 'OrderVolume', show: false, sortable: true },
  { name: 'TransactionTime', show: false, sortable: true, format: asTimeT },
]

export const aggregationBar: ITableColumn[] = [
  { name: 'Lp', show: true, sortable: false },
  { name: 'ExecutionId', show: true, sortable: false, format: formatNull },
  { name: 'LastVolume', show: true, sortable: false },
  { name: 'ExecutionTime', show: true, sortable: false },
  { name: 'LpPrice', show: true, sortable: false },
  { name: 'DealPart', show: true, sortable: false },
]

export const fixReports: ITableColumn[] = [
  { name: 'Session', show: true },
  { name: 'OrderId', show: true },
  { name: 'ClientOrderId', show: false },
  { name: 'OriginalClientOrderId', show: false, format: formatNull },
  { name: 'OrderStatus', show: false },
  { name: 'Account', show: true },
  { name: 'UserGroup', show: true },
  { name: 'Symbol', show: true },
  { name: 'Side', show: true },
  { name: 'OrderVolume', show: true },
  { name: 'OrderType', show: false },
  { name: 'Price', show: false },
  { name: 'TimeInForce', show: false },
  { name: 'LastVolume', show: true },
  { name: 'LotLastVolume', show: true, sortable: false },
  { name: 'LastPrice', show: true },
  { name: 'TransactionTime', format: asTime, show: true },
  { name: 'Comment', show: false },
  { name: 'Profit', show: true, sortable: false },
  { name: 'BrokerProfit', show: false },
  { name: 'LpExecutionTime', show: false, format: asMs },
  { name: 'FullExecutionTime', show: false, format: asMs },
  { name: 'LpPriceSlippage', show: false, sortable: false },
  { name: 'PlatformPriceSlippage', show: false, sortable: false },
  { name: 'TpMarkup', show: false, sortable: false },
  { name: 'TpBrokerProfit', show: false, sortable: false },
  { name: 'TotalBrokerProfit', show: false, sortable: false },
  { name: 'Commission', show: true },

  ////////////
  { name: 'ProcessingRuleName', show: false, sortable: true },
  { name: 'PlatformRequestPrice', show: false, sortable: true },
  { name: 'MarkupPips', show: false, sortable: false },
  { name: 'MarkupUsd', show: false, sortable: false },
  { name: 'LpExecutionPrice', show: false, sortable: true },
  { name: 'UsdConversionRate', show: false, sortable: true },
]

export const fixReportsNotComm: ITableColumn[] = [
  { name: 'Session', show: true },
  { name: 'OrderId', show: true },
  { name: 'ClientOrderId', show: false },
  { name: 'OriginalClientOrderId', show: false, format: formatNull },
  { name: 'OrderStatus', show: false },
  { name: 'Account', show: true },
  { name: 'UserGroup', show: true },
  { name: 'Symbol', show: true },
  { name: 'Side', show: true },
  { name: 'OrderVolume', show: true },
  { name: 'OrderType', show: false },
  { name: 'Price', show: false },
  { name: 'TimeInForce', show: false },
  { name: 'LastVolume', show: true },
  { name: 'LotLastVolume', show: true, sortable: false },
  { name: 'LastPrice', show: true },
  { name: 'TransactionTime', format: asTime, show: true },
  { name: 'Comment', show: false },
  { name: 'Profit', show: true, sortable: false },
  { name: 'BrokerProfit', show: false },
  { name: 'LpExecutionTime', show: false, format: asMs },
  { name: 'FullExecutionTime', show: false, format: asMs },
  { name: 'LpPriceSlippage', show: false, sortable: false },
  { name: 'PlatformPriceSlippage', show: false, sortable: false },
  { name: 'TpMarkup', show: false, sortable: false },
  { name: 'TpBrokerProfit', show: false, sortable: false },
  { name: 'TotalBrokerProfit', show: false, sortable: false },
  ////////////
  { name: 'ProcessingRuleName', show: false, sortable: true },
  { name: 'PlatformRequestPrice', show: false, sortable: true },
  { name: 'MarkupPips', show: false, sortable: false },
  { name: 'MarkupUsd', show: false, sortable: false },
  { name: 'LpExecutionPrice', show: false, sortable: true },
  { name: 'UsdConversionRate', show: false, sortable: true },
]

export const fixOrders: ITableColumn[] = [
  { name: 'Session', show: true },
  { name: 'ClientOrderId', show: true },
  { name: 'Symbol', show: true },
  { name: 'Account', show: true },
  { name: 'UserGroup', show: true },
  { name: 'Price', show: true },
  { name: 'TimeInForce', show: false },
  { name: 'Side', show: false },
  { name: 'OrderType', show: false },
  { name: 'Quantity', show: true },
  { name: 'LotQuantity', show: true, sortable: false },
  { name: 'FilledVolume', show: true, sortable: false },
  { name: 'RemainingVolume', show: false },
  { name: 'AveragePrice', show: true },
  { name: 'Status', show: false },
  { name: 'TransactionTime', format: asTime, show: true },
]

export const fixSwaps: ITableColumn[] = [
  { name: 'Session', show: true },
  { name: 'Symbol', show: true },
  { name: 'Account', show: true },
  { name: 'Time', format: asTime, show: true },
  { name: 'Multiplier', show: true },
  { name: 'Amount', show: true },
]
