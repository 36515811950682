import moment from 'moment'
import React from 'react'
import { Badge } from 'react-bootstrap'
import InfoItem from '../components/InfoItem'

export function buildInfoItems(items: any[], srcObj: any, translatePrefix: string, variant?: string, flagСolon?: boolean): JSX.Element[] {
  return items.map((item: any, index: number) => {
    const translateKey = typeof item === 'string' ? `${translatePrefix}.${item}` : `${translatePrefix}.${item.name}`
    const classNameBg = (item: any) => {
      if (srcObj[item] === 'BUY') {
        return 'bg-buy'
      } else if (srcObj[item] === 'Buy') {
        return 'bg-buy'
      } else if (srcObj[item] === 'Sell') {
        return 'bg-sell'
      } else if (srcObj[item] === 'SELL') {
        return 'bg-sell'
      } else if (`${srcObj[item]}` === 'true') {
        return 'bg-profit-minus badge badge-pill'
      }
    }

    const classNameBgDeals = (item: any) => {
      if (srcObj[item] === 'BUY') {
        return 'buyDeals'
      } else if (srcObj[item] === 'Buy') {
        return 'buyDeals'
      } else if (srcObj[item] === 'Sell') {
        return 'sellDeals'
      } else if (srcObj[item] === 'SELL') {
        return 'sellDeals'
      } else if (!srcObj[item]?.includes('-')) {
        return 'buyDeals'
      } else if (srcObj[item]?.includes('-')) {
        return 'minusProfitDeals'
      } else {
        return 'buyDeals'
      }
    }

    if (typeof item === 'string') {
      if (item.length === 0) {
        return <InfoItem variant={variant} key={Math.random()} first={''} second={''} flagСolon={flagСolon} />
      }
      return <InfoItem variant={variant} key={item} first={translateKey} second={srcObj[item]} flagСolon={flagСolon} />
    } else {
      // const val = Number.parseFloat(srcObj[item.name])
      switch (item.type) {
        case 'price':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className="bg-price">
                  {srcObj[item.name]}
                </Badge>
              }
              flagСolon={flagСolon}
            />
          )
        case 'brokerProfit':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} style={{ color: srcObj[item.name]?.includes('-') ? '#99423B' : '#30527A', fontSize: 14, fontWeight: 600, padding: 0, paddingLeft: 1, margin: 0 }}>
                  {`${srcObj[item.name]}` === 'true' ? 'Yes' : `${srcObj[item.name]}` === 'false' ? 'No' : srcObj[item.name]}
                </Badge>
              }
              flagСolon={flagСolon}
            />
          )

        case 'directionDeals':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={classNameBgDeals(item.name)}>
                  {`${srcObj[item.name]}` === 'true' ? 'Yes' : `${srcObj[item.name]}` === 'false' ? 'No' : srcObj[item.name]}
                </Badge>
              }
              flagСolon={flagСolon}
            />
          )
        case 'direction':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={classNameBg(item.name)}>
                  {`${srcObj[item.name]}` === 'true' ? 'Yes' : `${srcObj[item.name]}` === 'false' ? 'No' : srcObj[item.name]}
                </Badge>
              }
              flagСolon={flagСolon}
            />
          )
        case 'profit':
          return (
            <InfoItem
              flagСolon={flagСolon}
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={srcObj[item.name] === 0 ? 'bg-profit-plus' : 'bg-profit-minus'}>
                  {srcObj[item.name] === 0 ? 0 : srcObj[item.name]}
                </Badge>
              }
            />
          )
        case 'infoProfitDerection':
          return (
            <InfoItem
              flagСolon={flagСolon}
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={item.flag ? 'brokerProfit' : 'brokerProfitMinus'}>
                  {srcObj[item.name]}
                </Badge>
              }
            />
          )

        case 'profitDeals':
          return (
            <InfoItem
              flagСolon={flagСolon}
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={item.flag ? 'Profit' : 'brokerProfit'}>
                  {srcObj[item.name]}
                </Badge>
              }
            />
          )
        case 'profitMt4':
          return (
            <InfoItem
              flagСolon={flagСolon}
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={item.direction === 'BUY' ? 'buyDeals' : 'sellDeals'}>
                  {srcObj[item.name]}
                </Badge>
              }
            />
          )
        case 'time':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={moment(srcObj[item.name]).utc().format('YYYY/MM/DD HH:mm:ss')} flagСolon={flagСolon} />
        case 'timeDeals':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={moment(srcObj[item.name]).utc().format('YYYY-MM-DD HH:mm:ss')} flagСolon={flagСolon} />
        default:
          return <InfoItem variant={variant} key={item} first={translateKey} second={srcObj[item.name]} flagСolon={flagСolon} />
        case 'list':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={srcObj[item.name].toString()} flagСolon={flagСolon} />
        case 'visible':
          return <InfoItem variant={variant} key={item} first={translateKey} second={`#${srcObj[item.name]}`} flagСolon={flagСolon} />
        case 'timespan':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={moment(srcObj[item.name]).utc().format('HH:mm:ss')} flagСolon={flagСolon} />
        case 'checkbox':
          return <InfoItem variant={variant} key={Math.random()} first={translateKey} second={srcObj[item.name] ? 'true' : 'false'} flagСolon={flagСolon} />
        case 'password':
          return <InfoItem variant={variant} key={item} first={translateKey} second="********" flagСolon={flagСolon} />
        case 'ms':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={formatMs(srcObj[item.name])} flagСolon={flagСolon} />
      }
    }
  })
}

function formatMs(ms: number | undefined): string {
  if (typeof ms === 'number') {
    return `${ms} ms`
  }
  return 'n/a'
}

export function infoPassword(item: string): any {
  return {
    name: item,
    type: 'password',
  }
}

export function infoPrice(item: string): any {
  return {
    name: item,
    type: 'price',
  }
}

export function infoDirection(item: string): any {
  return {
    name: item,
    type: 'direction',
  }
}

export function infoDirectionDeals(item: string): any {
  return {
    name: item,
    type: 'directionDeals',
  }
}

export function brokerProfit(item: string): any {
  return {
    name: item,
    type: 'brokerProfit',
  }
}

export function infoProfit(item: string): any {
  return {
    name: item,
    type: 'profit',
  }
}

export function infoProfitDeals(item: string, flag?: boolean): any {
  return {
    name: item,
    type: 'profitDeals',
    flag: flag,
  }
}

export function infoProfitDerection(item: string, flag?: boolean): any {
  return {
    name: item,
    type: 'infoProfitDerection',
    flag: flag,
  }
}

export function infoProfitDealsMt4(item: string, flag?: boolean, direction?: string): any {
  return {
    name: item,
    type: 'profitMt4',
    flag: flag,
    direction: direction,
  }
}

export function infoTime(item: string): any {
  return {
    name: item,
    type: 'time',
  }
}

export function infoTimespan(item: string): any {
  return {
    name: item,
    type: 'timespan',
  }
}

export function infoTimeDeals(item: string): any {
  return {
    name: item,
    type: 'timeDeals',
  }
}

export function infoMs(item: string): any {
  return {
    name: item,
    type: 'ms',
  }
}

export function infoCheckbox(item: string): any {
  return {
    name: item,
    type: 'checkbox',
  }
}

export function infoList(item: string): any {
  return {
    name: item,
    type: 'list',
  }
}

export function idVisible(item: string): any {
  return {
    name: item,
    type: 'visible',
  }
}

export function buildVolumeDistribution(arr: any[], platform?: any) {
  if (!Array.isArray(arr)) {
    return <div />
  }

  const classNameBg = (item: any) => {
    if (item === 'BUY') {
      return 'bg-buy badge badge-pill'
    } else if (item === 'Buy') {
      return 'bg-buy badge badge-pill'
    } else if (item === 'Sell') {
      return 'bg-sell badge badge-pill'
    } else if (item === 'SELL') {
      return 'bg-sell badge badge-pill'
    }
  }
  return (
    <div className="row">
      {platform === 'MT4Deals' ? (
        <div className="col-2">
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            Pool
          </div>
          {arr.map((item: any) => (
            <div className="info-item default justify-content-center align-items-center d-flex">{item.Pool}</div>
          ))}
        </div>
      ) : platform === 'MT4Positions' ? null : (
        <div className="col-4">
          {arr.map((item: any) => (
            <InfoItem key={Math.random()} first="position.Pool" second={item.Pool} l={4} r={8} />
          ))}
        </div>
      )}

      {platform === 'MT5Positions' || platform === 'FixPositions' || platform === 'MT4Positions' ? null : <div className="border mr-2" />}
      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className="col-3">
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            LP
          </div>
          {arr.map((item: any) => (
            <div className="info-item default justify-content-center align-items-center d-flex">{item.Lp}</div>
          ))}
        </div>
      ) : platform === 'MT5Positions' || platform === 'FixPositions' ? null : (
        <div className="col-4 ml-3">
          {arr.map((item: any) => (
            <InfoItem key={Math.random()} first="position.Lp" second={item.Lp || 'N/A'} l={4} r={8} />
          ))}
        </div>
      )}

      {platform === 'MT4Positions' || platform === 'MT4Deals' ? <div className="border" /> : null}
      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className={platform === 'MT4Positions' ? 'col-2 mr-2 ml-2 d-flex flex-column align-items-center' : 'col-1 mr-2 ml-2'}>
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            Direction
          </div>
          {arr.map((item: any) => (
            <div style={{ fontSize: 9, height: 14, width: 36, marginBottom: 5 }} className={`info-item default justify-content-center align-items-center d-flex ${classNameBg(item.Direction)}`}>
              {item.Direction}
            </div>
          ))}
        </div>
      ) : null}

      <div className="border" />

      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className="col-3" style={{ width: 50 }}>
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            Volume (units)
          </div>
          {arr.map((item: any) => (
            <div className="info-item default justify-content-center align-items-center d-flex">{item.Volume}</div>
          ))}
        </div>
      ) : (
        <div className="col-3 ml-3">
          {arr.map((item: any) => (
            <InfoItem key={Math.random()} className={item.Direction === 'BUY' ? 'bg-buy' : 'bg-sell'} first="position.Volume" second={item.Volume} />
          ))}
        </div>
      )}

      {platform === 'MT4Positions' || platform === 'MT4Deals' ? <div className="border" /> : null}
      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className="col-2 mr-2 ml-2">
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold ">
            Mt4 order
          </div>
          {arr.map((item: any) => (
            <div style={{ height: 15, width: 33, marginRight: 'auto', marginLeft: 'auto', marginBottom: 5 }} className={`info-item default justify-content-center align-items-center d-flex`}>
              {item.Mt4Order}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export function buildSummaryDeals(arr: any[], platform?: any) {
  if (!Array.isArray(arr)) {
    return <div />
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <section className="SummaryWrapper">
        <span className="SummaryTitle">LP</span>
        {arr.map((item: any) => (
          <div className="SummaryContent d-flex align-items-center">
            <div className={item.IsAbook ? 'aBook' : 'bBook'}></div>
            {item.Lp}
          </div>
        ))}
      </section>
      <div className="border" />
      <section className="SummaryWrapper">
        <span className="SummaryTitle">Volume (units)</span>
        {arr.map((item: any) => (
          <div className="SummaryContent">
            <span className={item.Direction === 'BUY' || item.Direction === 'Buy' ? 'buy ' : 'sell '}>{item.Volume}</span>
          </div>
        ))}
      </section>
      <section className="SummaryWrapper">
        <span className="SummaryTitle">Pool</span>
        {arr.map((item: any) => (
          <div className="SummaryContent">{item.Pool}</div>
        ))}
      </section>

      {platform === 'mt4' && (
        <section className="SummaryWrapper" style={{ maxWidth: 52 }}>
          <span className="SummaryTitle">Side</span>
          {arr.map((item: any) => (
            <div className="SummaryContent">
              <span className={item.Direction === 'BUY' ? 'buy ' : 'sell '}>{item.Direction}</span>
            </div>
          ))}
        </section>
      )}

      <section className="SummaryWrapper">
        <span className="SummaryTitle">LP order ID</span>
        {arr.map((item: any) => (
          <div className="SummaryContent">{item.LpOrderId}</div>
        ))}
      </section>
      <section className="SummaryWrapper">
        <span className="SummaryTitle">Client order ID</span>
        {arr.map((item: any) => (
          <div className="SummaryContent_last">{item.ClientOrderId}</div>
        ))}
      </section>
    </div>
  )
}
