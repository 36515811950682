import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, selectInput, sselectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'
import { BinanceRuleEntity } from '../../entity/binance'
import { addBinanceFuturesSwap, ModifyBinanceFutures } from '../../redux/reducers/BinanceReducer'
import { fetchPlatformMt5, getBinanceSwapRuleItems } from '../../redux/actions/binance-actions'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'

const BinanceFuturesSwapBar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const newItem = {
    ...item,
    Platform: { value: item.Platform, label: item.Platform },
    PositiveSwapsMultiplier: String(item.PositiveSwapsMultiplier),
    NegativeSwapsMultiplier: String(item.NegativeSwapsMultiplier),
  }
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(newItem, BinanceRuleEntity.schema)
  const { gateway } = useSelector((reduxState: any) => reduxState.gateways)
  const [platform, setPlatform] = useState([])

  useEffect(() => {
    dispatch(fetchPlatformMt5({ gateway, setPlatform }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'add') {
      setInputState({ ...inputState, Platform: { value: platform[0], label: platform[0] } })
    }
  }, [platform]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (type === 'add' || type === 'clone') {
      dispatch(addBinanceFuturesSwap({ params, body: { ...inputState, Platform: inputState.Platform.value } }))
      dispatch(getBinanceSwapRuleItems({ ...params, body: { ...inputState, Platform: inputState.Platform.value } }))
    } else if (type === 'modify') {
      dispatch(ModifyBinanceFutures({ params, body: { ...inputState, Platform: inputState.Platform.value } }))
      dispatch(getBinanceSwapRuleItems({ ...params }))
    }
    dispatch(hideRightBar())
  }

  const controls = buildControlsExtTwoPerLine(
    [textInput('Symbols', false, '', '', 'Mask', true), textInput('Groups', false, '', '', 'Mask', true), sselectInput('Platform', buildMultiselectOptionsFromArray(platform)), textInput('PositiveSwapsMultiplier'), textInput('NegativeSwapsMultiplier')],
    inputState,
    setInputState,
    'binance-futures',
    touched,
    setTouched,
    errors,
  )
  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`binance-futures.${type}`} />,
          item: controls,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default BinanceFuturesSwapBar
