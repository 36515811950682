import React, { useEffect } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { expToNum } from '../../utils/num-utils'
import { transformEmptyStringMaxLength } from '../../utils/schema-utils'

interface ITextInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  flag?: boolean
  tooltipFlag?: boolean
  tooltip?: string

  setState(state: any): any

  setTouched?(touched: any): any
}

const TextInput: React.FC<ITextInput> = props => {
  const { state, setState, touched, setTouched, errors, errorText, name, className, placeholder, label, isDisabled, flag, tooltipFlag, tooltip } = props

  useEffect(() => {
    if (name === 'Threshold') {
      setState({ ...state, Threshold: expToNum(state.Threshold) })
    }
  }, [name])

  let validThreshold = false

  if (name === 'Threshold') {
    validThreshold = !(name === 'Threshold' && +state.Threshold >= 0 && String(state.Threshold).indexOf(',') === -1 && String(state.Threshold).indexOf('e') === -1)
  } else if (name === 'Price') {
    if (!state.Price.match(/^[0-9.]+$/gi)) {
      validThreshold = true
    } else {
      const [one, two] = String(state?.Price)?.split('.')
      validThreshold = !(name === 'Price' && one?.length <= 10 && two ? two?.length <= 8 : true && +state.Price >= 0 && String(state.Price).indexOf(',') === -1 && String(state.Price).indexOf('e') === -1)
    }
  }

  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const nameIncludes = ['Percent', 'Threshold', 'Price']

  return (
    <>
      {tooltipFlag ? (
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
          <Form.Group className={className}>
            {label ? (
              <>
                <Form.Label>
                  <FormattedMessage id={label} tagName="span" />
                </Form.Label>
                <span>:</span>
              </>
            ) : null}
            <Form.Control
              type="text"
              placeholder={placeholder}
              value={state[name]}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!!isDisabled}
              isInvalid={(flag ? validThreshold : errors) && (flag ? true : touched) && (flag ? true : touched[name]) && errors && (flag ? `${name}s` : errors[name])}
            />
            <Form.Control.Feedback type="invalid">
              {!nameIncludes.includes(name) ? (
                <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
              ) : (
                <div className="d-block invalid-feedback" style={{ fontSize: '12.8px' }}>
                  <FormattedMessage id="field-validation-error" tagName="span" />
                </div>
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group className={className}>
          {label ? (
            <>
              <Form.Label>
                <FormattedMessage id={label} tagName="span" />
              </Form.Label>
              <span>:</span>
            </>
          ) : null}
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={state[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!isDisabled}
            isInvalid={(flag ? validThreshold : errors) && (flag ? true : touched) && (flag ? true : touched[name]) && errors && (flag ? `${name}s` : errors[name])}
          />
          <Form.Control.Feedback type="invalid">
            {!nameIncludes.includes(name) ? (
              <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
            ) : (
              <div className="d-block invalid-feedback" style={{ fontSize: '12.8px' }}>
                <FormattedMessage id="field-validation-error" tagName="span" />
              </div>
            )}
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </>
  )
}

export default TextInput
